/* Recommended: 0.2s ease-out */
@keyframes enter-going-down {
    from {
        transform: translate(-50%, -60%);
    }
    to {
        transform: translate(-50%, -50%);
    }
}
/* Recommended: 0.2s linear */
@keyframes enter-scaling {
    from {
        transform: scale(0.8) translate(-50%, -50%);
        transform-origin: left;
    }
    to {
        transform: scale(1) translate(-50%, -50%);
        transform-origin: left;
    }
}

/* Recommended: 0.1s ease-out */
@keyframes enter-scale-down {
    from {
        transform: scale(1.5) translate(-50%, -60%);
        transform-origin: left;
    }
    to {
        transform: scale(1) translate(-50%, -50%);
        transform-origin: left;
    }
}

/* Recommended: 0.3s linear */
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Recommended: 0.3s linear */
@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Recommended: 0.7s linear */
@keyframes bounce-in {
    0% {
        transform: translate(-50%, -85%);
    }
    20%,
    80%,
    100% {
        transform: translate(-50%, -50%);
    }
    60% {
        transform: translate(-50%, -65%);
    }
    90% {
        transform: translate(-50%, -53%);
    }
}

/* Recommended: 1s linear */
@keyframes scale-rotate {
    30% {
        transform: scale(1.05) translate(-50%, -50%);
        transform-origin: left;
    }
    40%,
    60% {
        transform: rotate(-3deg) scale(1.05) translate(-50%, -50%);
        transform-origin: left;
    }
    50% {
        transform: rotate(3deg) scale(1.05) translate(-50%, -50%);
        transform-origin: left;
    }
    70% {
        transform: rotate(0deg) scale(1.05) translate(-50%, -50%);
        transform-origin: left;
    }
    100% {
        transform: scale(1) translate(-50%, -50%);
        transform-origin: left;
    }
}
