"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SleepUploadType = void 0;
var SleepUploadType;
(function (SleepUploadType) {
  SleepUploadType[SleepUploadType["UNKNOWN"] = 0] = "UNKNOWN";
  SleepUploadType[SleepUploadType["MANUAL"] = 1] = "MANUAL";
  SleepUploadType[SleepUploadType["AUTOMATIC"] = 2] = "AUTOMATIC";
  SleepUploadType[SleepUploadType["TENTATIVE"] = 3] = "TENTATIVE";
  SleepUploadType[SleepUploadType["INDETERMINATE"] = 4] = "INDETERMINATE";
})(SleepUploadType = exports.SleepUploadType || (exports.SleepUploadType = {}));